@tailwind base;
@tailwind components;
@tailwind utilities;

/* Bangla Font */
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Bengali&display=swap");

.bangla-font {
    font-family: "Noto Serif Bengali", serif;
}

/* Swiper */
.swiper-button-next::after,
.swiper-button-prev::after {
    font-size: 15px !important;
    font-weight: bold !important;
    color: #ff9100 !important;
}

.swiper-button-next,
.swiper-button-prev {
    background-color: rgba(255, 255, 255, 1) !important;
    border-radius: 100% !important;
    width: 30px !important;
    height: 30px !important;
}

.swiper-pagination-bullet {
    margin-top: 100px !important;
}

.swiper-pagination-bullet {
    background-color: black !important;
}

.swiper-pagination-bullet-active {
    background-color: #ff9100 !important;
}

/* Quiz */
.answer:hover .option {
    background-color: #ff9100;
}

.quiz-cover-img {
    background-image: url("./images/Dhakar-Pola-Cover.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
